import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 15px 22px 0 0;
  &.dark {
    background-color: #2a2d30;
  }
  &.light {
    background-color: #fafbfc;
  }
`;

export const DataSelectorContainer = styled.div`
  .initial {
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    left: 10px;
  }
  .final {
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    left: 10px;
  }
  .dateInput {
    width: 213px;
    height: 30px;
    text-indent: 5px;
    margin-right: 10px;
    margin-top: 1px;
    font-family: 'Roboto', sans-serif;
    padding-right: 5px;
    padding-left: 15px;
    border-radius: 10px;
    border: none;
    outline: none;
    color: #fafbfc;
  }
  .dateInput:focus {
    border: none;
  }
  .dateInputDark {
    background-color: #0073ea;
  }
  .dateInputLight {
    background-color: #83a1ea;
  }

  .labelDark {
    color: #fafbfc;
  }
  .labelLight {
    color: #2c3135;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DateFilterButton = styled.button`
  border-radius: 5px;
  border: none;
  font-size: 1rem;
  padding: 0.2rem 1rem;
  margin-top: 5px;
  background: #0073EA;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #FAFBFC};
  font-weight: bold;

  transition: filter 0.2s;

  &:hover {
    background-color: #00468E;
  }
`;

export const ClearFilterButton = styled.button`
  font-size: 1rem;
  padding: 0.2rem 1rem;
  margin-top: 5px;
  margin-left: 1rem;
  border-radius: 5px;
  border: none;
  background-color: #0073ea;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fafbfc;
  font-weight: bold;

  transition: filter 0.2s;

  &:hover {
    background-color: #00468e;
  }
`;
