import styled from 'styled-components';

export const Button = styled.button`
  width: 97%;
  height: 3rem;
  margin: 5px auto;
  color: #fafbfc;
  gap: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${props => props.theme.fonts.mainFont};
  font-size: 1.3rem;
  font-weight: bold;

  background-color: #0073ea;
  border-radius: 5px;
  border: 0;

  transition: filter 0.2s;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  &:hover {
    background-color: #00468e;
  }

  svg {
    font-size: 1.5rem;
  }
`;

export const PopoverTitle = styled.p`
  color: ${props => props.theme.colors.black};
  margin: 1rem 1rem;
  font-family: ${props => props.theme.fonts.textFont};
  font-size: 1rem;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const PopoverConfirmButton = styled.div`
  border-radius: 8px;
  height: 2.5rem;
  width: 25%;
  padding: 0 1rem;
  border: none;
  font-size: 0.8rem;
  background: ${props => props.theme.colors.green};
  box-shadow: 0 3px 4px -2px ${props => props.theme.colors.shadowColor};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.textFont};
  letter-spacing: 1px;

  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const PopoverDenyButton = styled.div`
  border-radius: 8px;
  height: 2.5rem;
  width: 25%;
  padding: 0 1rem;
  border: none;
  font-size: 0.8rem;
  background: ${props => props.theme.colors.red};
  box-shadow: 0 3px 4px -2px ${props => props.theme.colors.shadowColor};

  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.textFont};
  letter-spacing: 1px;

  cursor: pointer;

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
`;
