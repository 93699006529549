import React, { useEffect } from 'react';

import { Header } from '../../components/Header';
import { FiltersBar } from '../../components/FiltersBar';
import { HashtagsTable } from '../../components/HashtagsTable';

import { useMembersTags } from '../../hooks/useMembersTags';
import { useSidebar } from '../../hooks/useSidebar';

import { Container, ButtonContainer, LoadMoreButton } from './styles';

export function Hashtags() {
  const {
    isEmpty,
    isLoading,
    isFiltered,
    isLoadingNewMembers,
    isTableFull,
    membersNames,
    members,
    getMembers,
    getDepartments,
    getMembersNames,
    getLocales,
    getMoreMembers,
  } = useMembersTags();

  useEffect(() => {
    getMembers();
    getDepartments();
    getMembersNames();
    getLocales();
  }, []);

  const isLoadedAllMembers = members.length === membersNames.length;
  const { theme } = useSidebar();

  return (
    <>
      <Header />
      <Container className={theme ? 'dark' : 'light'}>
        <FiltersBar />
        <HashtagsTable />
        <ButtonContainer>
          {!isEmpty &&
            !isLoading &&
            !isLoadingNewMembers &&
            !isFiltered &&
            !isLoadedAllMembers &&
            !isTableFull && (
              <LoadMoreButton onClick={getMoreMembers} type="button">
                Carregar mais
              </LoadMoreButton>
            )}
        </ButtonContainer>
      </Container>
    </>
  );
}
