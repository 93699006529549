import React from 'react';

import { useMembersTags } from '../../hooks/useMembersTags';
import { useSidebar } from '../../hooks/useSidebar';

import { StyledTable } from './styles';

export function HashtagsTable() {
  const { members } = useMembersTags();
  const { theme } = useSidebar();
  return (
    <StyledTable>
      <table>
        <thead className={theme ? 'dark' : 'light'}>
          <tr>
            <th className={theme ? 'dark' : 'light'}>Nome</th>
            <th className={theme ? 'dark' : 'light'}>Departamento</th>
            <th className={theme ? 'dark' : 'light'}>Localização</th>
            <th className={theme ? 'dark' : 'light'}>#momentosauderd</th>
            <th className={theme ? 'dark' : 'light'}>Total</th>
          </tr>
        </thead>
        <tbody className={theme ? 'dark' : 'light'}>
          {members.length !== 0 ? (
            <>
              {members.map(member => (
                <tr key={member.id}>
                  <td className={theme ? 'dark' : 'light'}>{member.name}</td>
                  <td className={theme ? 'dark' : 'light'}>
                    {member.department}
                  </td>
                  <td className={theme ? 'dark' : 'light'}>{member.locale}</td>
                  <td className={theme ? 'dark' : 'light'}>
                    {member.score.momentosaudeRD}
                  </td>
                  <td className={theme ? 'dark' : 'light'}>
                    {member.totalScore}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td> </td>
              <td colSpan={9}>
                <h3 className={theme ? 'dark' : 'light'}>
                  Ainda nada por aqui{' '}
                </h3>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </StyledTable>
  );
}
